<template>
  <div>
    <b-row class="mt-0 mt-lg-3">
      <b-col xl="3">
        <card v-if="mod" :card="mod" :link="false" :seeMore="true" />
        <gauge
          v-if="isProfileLoaded"
          :title="$t('generic.modules.cybersecurity.title')"
          :value="
            cybersecurity.average !== false
              ? parseFloat(cybersecurity.average * 10)
              : cybersecurity.average
          "
          :info="$t('dashboard.awareness.info_icon')"
          class="mt-8"
          :formatter="
            _val => {
              return cybersecurity.average !== false
                ? $n((Math.round(cybersecurity.average * 10) / 10).toFixed(1)) +
                    '/10'
                : '?/10';
            }
          "
        />
      </b-col>
      <b-col xl="9">
        <b-row no-gutters>
          <b-col xl="12">
            <chart
              :title="$t('generic.modules.cybersecurity.title')"
              :subtitle="$t('generic.modules.cybersecurity.subtitle')"
              :actions="false"
              height="200px"
              :series="[
                {
                  name: $t('generic.modules.cybersecurity.title'),
                  data: cybersecurity.series
                }
              ]"
              :categories="cybersecurity.labels"
              :dates="cybersecurity.dates"
              :discrete="cybersecurity.discrete"
              v-if="isProfileLoaded && cybersecurity.series.length > 0"
            />
          </b-col>
          <b-col xl="12" v-if="tabs.length > 0">
            <tabs
              :tabs="tabs"
              :title="$t('generic.modules.awareness.recommendations')"
            />
          </b-col>
          <div v-else>
            <b-card
              class="card-custom gutter-b"
              body-class="p-0 d-flex flex-column"
              header-class="border-0 pt-5"
            >
              <template #header>
                <b-card-title class="font-weight-bolder">
                  <div class="card-label">
                    {{ $t("profile.awareness.help.title") }}
                  </div>
                </b-card-title>
              </template>
              <div class="card-body text-left">
                <p
                  v-for="(value, key) in $t(
                    'profile.awareness.help.description'
                  )"
                  :key="key"
                  v-html="value"
                />
              </div>
            </b-card>
            <empty-card type="profile.awareness" />
          </div>
        </b-row>
        <b-row class="mt-8">
          <b-col
            lg="4"
            class="mb-8"
            v-for="interaction in visibleInteractions"
            :key="interaction.surveyEntityId"
          >
            <card :card="interaction" :date="interaction.date" />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";
import * as Sentry from "@sentry/vue";

import { getUserActions } from "@/api/user/traits.api";

import Card from "@/components/profile/Card.component";
import Chart from "@/components/profile/Chart.component";
import Tabs from "@/components/training/Tabs.component";
import Gauge from "@/components/profile/Gauge.component";
import EmptyCard from "@/components/EmptyCard.component.vue";

export default {
  name: "Awareness",
  components: {
    Card,
    Chart,
    Tabs,
    Gauge,
    EmptyCard
  },
  methods: {
    ...mapActions("Profile", ["loadProfile"]),
    ...mapActions("Interactions", ["loadInteractions"]),
    loadRecommendations() {
      var cyberActionParams = {
        surveyTypes: [this.getConstant("SURVEY_KYMATIO_CYBERSECURITY_GLOBAL")],
        groupDimensions: [this.getConstant("DIMENSION_GROUP_CIBERSECURITY")],
        actionTypes: [
          this.getConstant("ACTIONS_TYPE_CYBER_PILLS"),
          this.getConstant("ACTIONS_TYPE_CYBER_RESULT")
        ],
        entityType: this.getConstant("ENTITY_TYPE_USER"),
        maxValue: "NO"
      };
      getUserActions(cyberActionParams).then(res => {
        this.tabs = res.data.records;

        let tab = _.find(this.tabs, tab => {
          return tab.executeAction.title === this.$tc("profile.tabs.result");
        });

        this.tabs = _.remove(this.tabs, tab => {
          return tab.executeAction.title !== this.$tc("profile.tabs.result");
        });
        if (tab) {
          this.tabs.unshift(tab);
        }
      });
    }
  },
  data() {
    return {
      tabs: []
    };
  },
  computed: {
    ...mapGetters("Profile", ["isProfileLoaded", "cybersecurity", "modules"]),
    ...mapGetters("Constants", ["isConstantsActive", "getConstant"]),
    ...mapGetters("Interactions", [
      "isInteractionsLoaded",
      "interactions",
      "getByType"
    ]),
    visibleInteractions() {
      return this.getByType(
        this.getConstant("SURVEYTYPE_KYMATIO_CYBERSECURITY")
      );
    },
    mod() {
      const module = _.find(this.modules, e => e.id === "awareness");
      if (!module) {
        Sentry.captureMessage("Module 'awareness' not found in Awareness", {
          level: "warning",
          extra: {
            availableModules: this.modules
          }
        });
      }
      return module || null;
    }
  },
  mounted() {
    if (this.isConstantsActive && !this.isProfileLoaded) this.loadProfile();
    if (this.isConstantsActive && !this.isInteractionsLoaded)
      this.loadInteractions();
    if (this.isConstantsActive) this.loadRecommendations();
  },
  watch: {
    isConstantsActive(value) {
      if (value === true) {
        this.loadProfile();
        this.loadInteractions();
        this.loadRecommendations();
      }
    }
  }
};
</script>
