var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-row',{staticClass:"mt-0 mt-lg-3"},[_c('b-col',{attrs:{"xl":"3"}},[(_vm.mod)?_c('card',{attrs:{"card":_vm.mod,"link":false,"seeMore":true}}):_vm._e(),(_vm.isProfileLoaded)?_c('gauge',{staticClass:"mt-8",attrs:{"title":_vm.$t('generic.modules.cybersecurity.title'),"value":_vm.cybersecurity.average !== false
            ? parseFloat(_vm.cybersecurity.average * 10)
            : _vm.cybersecurity.average,"info":_vm.$t('dashboard.awareness.info_icon'),"formatter":_val => {
            return _vm.cybersecurity.average !== false
              ? _vm.$n((Math.round(_vm.cybersecurity.average * 10) / 10).toFixed(1)) +
                  '/10'
              : '?/10';
          }}}):_vm._e()],1),_c('b-col',{attrs:{"xl":"9"}},[_c('b-row',{attrs:{"no-gutters":""}},[_c('b-col',{attrs:{"xl":"12"}},[(_vm.isProfileLoaded && _vm.cybersecurity.series.length > 0)?_c('chart',{attrs:{"title":_vm.$t('generic.modules.cybersecurity.title'),"subtitle":_vm.$t('generic.modules.cybersecurity.subtitle'),"actions":false,"height":"200px","series":[
              {
                name: _vm.$t('generic.modules.cybersecurity.title'),
                data: _vm.cybersecurity.series
              }
            ],"categories":_vm.cybersecurity.labels,"dates":_vm.cybersecurity.dates,"discrete":_vm.cybersecurity.discrete}}):_vm._e()],1),(_vm.tabs.length > 0)?_c('b-col',{attrs:{"xl":"12"}},[_c('tabs',{attrs:{"tabs":_vm.tabs,"title":_vm.$t('generic.modules.awareness.recommendations')}})],1):_c('div',[_c('b-card',{staticClass:"card-custom gutter-b",attrs:{"body-class":"p-0 d-flex flex-column","header-class":"border-0 pt-5"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('b-card-title',{staticClass:"font-weight-bolder"},[_c('div',{staticClass:"card-label"},[_vm._v(" "+_vm._s(_vm.$t("profile.awareness.help.title"))+" ")])])]},proxy:true}])},[_c('div',{staticClass:"card-body text-left"},_vm._l((_vm.$t(
                  'profile.awareness.help.description'
                )),function(value,key){return _c('p',{key:key,domProps:{"innerHTML":_vm._s(value)}})}),0)]),_c('empty-card',{attrs:{"type":"profile.awareness"}})],1)],1),_c('b-row',{staticClass:"mt-8"},_vm._l((_vm.visibleInteractions),function(interaction){return _c('b-col',{key:interaction.surveyEntityId,staticClass:"mb-8",attrs:{"lg":"4"}},[_c('card',{attrs:{"card":interaction,"date":interaction.date}})],1)}),1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }